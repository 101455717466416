<template>
  <v-dialog v-model="value" persistent scrollable max-width="70vw">
  <v-card v-if="promotion">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Email this Promotion</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        This will only be sent to your own email address
      </v-card-title>
    <v-card-actions>
      <v-btn color="success" class="mr-4" @click.native="emailPromotion()">
        Send
      </v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
import PromotionApi from "@/api/promotion"

export default {
  props: {
    value: Boolean,
    promotion: Object,
  },
  components: {
  },
  data() {
    return {
    }
  },
  async created() {},
  mounted: function () {},
  computed: {},
  watch: {},
  methods: {
    close() {
      this.$emit('close')
    },
    emailPromotion() {
      PromotionApi.emailPromotion({ promotion: this.promotion, template: 'marketArtistNewWork' }).then((result) => {
        console.log(result.data)
        this.close()
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
  }
}
</script>
